import React from 'react';
export default function ExpandedUserDetails(props) {
    const styles = {
        container: {
            marginLeft: '50px',
            fontFamily: 'sans-serif',
        },
    };
    return (React.createElement("div", { style: styles.container, "data-testid": 'expandedUserDetails' },
        React.createElement("h3", null, "User info"),
        React.createElement("pre", null, JSON.stringify(props.data, null, 2))));
}
