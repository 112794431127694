import React, { useState } from 'react';
import { Icon } from '@watts/design-system-react';
export default function ShowText({ text }) {
    const [textDisplayed, setTextDisplayed] = useState(false);
    function toggleState() {
        setTextDisplayed(!textDisplayed);
    }
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center',
            padding: '6px 8px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
        text: {
            marginRight: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333',
        },
    };
    return (React.createElement("div", { "data-testid": 'showText', style: styles.container, onClick: toggleState },
        React.createElement("p", { style: styles.text }, textDisplayed ? text : '********'),
        textDisplayed ? React.createElement(Icon, { icon: 'eye-closed' }) : React.createElement(Icon, { icon: 'eye-open' })));
}
