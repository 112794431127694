import * as React from 'react';
import { httpClient } from '../../httpClient';
import { Button, Divider, TextField, Combobox, Message, } from '@watts/design-system-react';
import { useToast } from '@watts/design-system-react';
import { UserTypes } from '../../types/user-types';
import { Areas } from '../../types/areas';
import { useUser } from '../../user-context';
import { Environments } from '../../types/environments';
import { Opcos } from '../../types/opcos';
import { Regions } from '../../types/regions';
import { Teams } from '../../types/teams';
export default function UserForm({ initialUserInfo, onSubmitSuccess }) {
    const { sendToast } = useToast();
    const { requestedBy } = useUser();
    const requiredFields = ['email', 'password', 'area', 'ownerTeam', 'opco', 'type'];
    const [userInfo, setUserInfo] = React.useState({
        _id: undefined,
        email: undefined,
        password: undefined,
        label: undefined,
        type: undefined,
        area: undefined,
        ownerTeam: undefined,
        accountId: undefined,
        accountName: undefined,
        attachedAccounts: undefined,
        permissions: undefined,
        description: undefined,
        firstName: undefined,
        lastName: undefined,
        fullName: undefined,
        userId: undefined,
        clientId: undefined,
        clientSecret: undefined,
        mfaSecret: undefined,
        envName: undefined,
        opco: undefined,
    });
    const userTypeOptions = UserTypes.map((type) => ({ value: type, label: type }));
    const areas = Areas.map((area) => ({ value: area, label: area }));
    const environments = Environments.map((env) => ({ value: env, label: env }));
    const opcos = Opcos.map((opco) => ({ value: opco, label: opco }));
    const regions = Regions.map((region) => ({ value: region, label: region }));
    const teams = Teams.map((team) => ({ value: team, label: team }));
    React.useEffect(() => {
        if (initialUserInfo) {
            setUserInfo(initialUserInfo);
        }
    }, [initialUserInfo]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        const arrayFields = ['attachedAccounts', 'permissions'];
        if (arrayFields.includes(name)) {
            setUserInfo({ ...userInfo, [name]: value.split(',') });
        }
        else {
            setUserInfo({ ...userInfo, [name]: value });
        }
    };
    const handleValueChange = (name, value) => {
        if (userInfo[name] === value)
            return;
        setUserInfo(prevState => ({ ...prevState, [name]: value }));
    };
    const mandatoryFieldsFilled = async () => {
        const missingFields = requiredFields.filter(field => !userInfo[field]);
        if (missingFields.length > 0) {
            await sendToast({
                title: 'Error',
                message: `Missing required fields: ${missingFields.join(', ')}`,
                status: 'error',
                closeLabel: '',
                duration: 'short',
            });
            return false;
        }
        else {
            return true;
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!await mandatoryFieldsFilled()) {
            return;
        }
        const res = initialUserInfo ? await httpClient.updateUser(requestedBy, userInfo) : await httpClient.createUser(requestedBy, userInfo);
        if (res.status === 201 || res.status === 200) {
            await sendToast({
                title: 'Success',
                message: 'User successfully created or modified',
                status: 'success',
                closeLabel: '',
                duration: 'short',
            });
            if (onSubmitSuccess) {
                onSubmitSuccess();
            }
        }
        else {
            await sendToast({
                title: 'Error',
                message: 'An error occurred while creating or modifying the user',
                status: 'error',
                closeLabel: '',
                duration: 'short',
            });
        }
    };
    const styles = {
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '1em',
            padding: '2em',
            width: '100%',
            boxSizing: 'border-box',
        },
        containerChild: {
            maxWidth: '100%',
        },
    };
    return (React.createElement("div", { "data-testid": 'userForm' },
        React.createElement("form", { onSubmit: handleSubmit },
            userInfo?._id &&
                React.createElement("div", { style: styles.container },
                    React.createElement(TextField, { label: '_id', "data-testid": '_id', name: '_id', textFieldSize: 's', type: 'text', value: userInfo._id ?? '', "aria-required": "true", disabled: true })),
            React.createElement("div", { style: styles.container },
                React.createElement(TextField, { label: 'Email *', name: 'email', textFieldSize: 's', type: 'text', value: userInfo.email ?? '', onChange: handleChange, "data-testid": 'email', "aria-required": "true" }),
                React.createElement(TextField, { label: 'Password *', name: 'password', textFieldSize: 's', type: 'text', value: userInfo.password ?? '', onChange: handleChange, "data-testid": 'password', "aria-required": "true" }),
                React.createElement(Combobox, { label: 'Type *', name: 'type', options: userTypeOptions, defaultSelectedOption: userTypeOptions.find(option => option.value.toLowerCase() === initialUserInfo?.type?.toLowerCase()) ?? userTypeOptions[0], onValueChange: (value) => handleValueChange('type', value), "data-testid": 'type', noMatchLabel: '' }),
                React.createElement(Combobox, { label: 'Area *', name: 'area', options: areas, defaultSelectedOption: areas.find(option => option.value.toLowerCase() === initialUserInfo?.area?.toLowerCase()) ?? areas[0], onValueChange: (value) => handleValueChange('area', value), "data-testid": 'area', noMatchLabel: '' }),
                React.createElement(Combobox, { label: 'OwnerTeam *', name: 'ownerTeam', options: teams, defaultSelectedOption: teams.find(option => option.value.toLowerCase() === initialUserInfo?.ownerTeam?.toLowerCase()) ?? teams[0], onValueChange: (value) => handleValueChange('ownerTeam', value), "data-testid": 'ownerTeam', noMatchLabel: '' }),
                React.createElement(Combobox, { label: 'Opco *', name: 'opco', options: opcos, defaultSelectedOption: opcos.find(option => option.value.toLowerCase() === initialUserInfo?.opco?.toLowerCase()) ?? environments[0], onValueChange: (value) => handleValueChange('opco', value), "data-testid": 'opco', noMatchLabel: '' })),
            React.createElement(Divider, null),
            React.createElement(Message, { variant: 'colorful', title: 'The following fields are not set to be mandatory as they are not all relevant for all Digital Factory projects. We strongly suggest filling them with all information possible for better data management. Additionally, based on this data, the API will be able to provide accurate results.', description: '', closeLabel: '' }),
            React.createElement("div", { style: styles.container },
                React.createElement(TextField, { label: 'Label', name: 'label', textFieldSize: 's', type: 'text', value: userInfo.label ?? '', onChange: handleChange, "data-testid": 'label' }),
                React.createElement(Combobox, { label: 'Region', name: 'region', options: regions, defaultSelectedOption: regions.find(option => option.value.toLowerCase() === initialUserInfo?.region?.toLowerCase()) ?? environments[0], onValueChange: (value) => handleValueChange('region', value), "data-testid": 'region', noMatchLabel: '' }),
                React.createElement(Combobox, { label: 'Environment', name: 'envName', options: environments, defaultSelectedOption: environments.find(option => option.value.toLowerCase() === initialUserInfo?.envName?.toLowerCase()) ?? environments[0], onValueChange: (value) => handleValueChange('envName', value), "data-testid": 'envName', noMatchLabel: '' }),
                React.createElement(TextField, { label: 'Account Id', name: 'accountId', textFieldSize: 's', type: 'text', value: userInfo.accountId ?? '', onChange: handleChange, "data-testid": 'accountId' }),
                React.createElement(TextField, { label: 'Account name', name: 'accountName', textFieldSize: 's', type: 'text', value: userInfo.accountName ?? '', onChange: handleChange, "data-testid": 'accountName' }),
                React.createElement(TextField, { label: 'Description', name: 'description', textFieldSize: 's', type: 'text', value: userInfo.description ?? '', onChange: handleChange, "data-testid": 'description' }),
                React.createElement(TextField, { label: 'First name', name: 'firstName', textFieldSize: 's', type: 'text', value: userInfo.firstName ?? '', onChange: handleChange, "data-testid": 'firstName' }),
                React.createElement(TextField, { label: 'Last name', name: 'lastName', textFieldSize: 's', type: 'text', value: userInfo.lastName ?? '', onChange: handleChange, "data-testid": 'lastName' }),
                React.createElement(TextField, { label: 'Full name', name: 'fullName', textFieldSize: 's', type: 'text', value: userInfo.fullName ?? '', onChange: handleChange, "data-testid": 'fullName' }),
                React.createElement(TextField, { label: 'User Id', name: 'userId', textFieldSize: 's', type: 'text', value: userInfo.userId ?? '', onChange: handleChange, "data-testid": 'userId' }),
                React.createElement(TextField, { label: 'Client Id', name: 'clientId', textFieldSize: 's', type: 'text', value: userInfo.clientId ?? '', onChange: handleChange, "data-testid": 'clientId' }),
                React.createElement(TextField, { label: 'Client secret', name: 'clientSecret', textFieldSize: 's', type: 'text', value: userInfo.clientSecret ?? '', onChange: handleChange, "data-testid": 'clientSecret' }),
                React.createElement(TextField, { label: 'MFA secret', name: 'mfaSecret', textFieldSize: 's', type: 'text', value: userInfo.mfaSecret ?? '', onChange: handleChange, "data-testid": 'mfaSecret' }),
                React.createElement(TextField, { label: 'Attached accounts', name: 'attachedAccounts', helperText: { text: 'Comma separated list of account IDs' }, textFieldSize: 's', type: 'text', value: userInfo.attachedAccounts?.join(',') ?? '', onChange: handleChange, "data-testid": 'attachedAccounts' }),
                React.createElement(TextField, { label: 'Permissions', name: 'permissions', helperText: { text: 'Comma separated list of permissions' }, textFieldSize: 's', type: 'text', value: userInfo.permissions?.join(',') ?? '', onChange: handleChange, "data-testid": 'permissions' })),
            React.createElement("br", null),
            React.createElement(Button, { variant: 'primary', type: 'submit', "data-testid": 'submit' }, "Submit"))));
}
