import React, { useEffect, useState } from 'react';
import { PageTypes } from '../../types/page-types';
export default function Pages({ onChangeTab }) {
    const [selectedTab, setSelectedTab] = useState(PageTypes['SEARCH']);
    useEffect(() => {
        onChangeTab(selectedTab);
    }, [selectedTab]);
    const styles = {
        tabs: {
            display: 'flex',
            justifyContent: 'center',
            margin: '1em',
        },
        tab: {
            padding: '0.5em',
            margin: '0.5em',
            cursor: 'pointer',
            borderRadius: '5px',
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
        },
        selectedTab: {
            padding: '0.5em',
            margin: '0.5em',
            cursor: 'pointer',
            borderRadius: '5px',
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#ccc',
        },
    };
    return (React.createElement("div", null,
        React.createElement("div", { style: styles.tabs, "data-testid": 'pages' },
            React.createElement("div", { "data-testid": 'search', 
                // style={`"tab" ${selectedTab === PageTypes.SEARCH ? "selectedTab" : ''}`}
                style: selectedTab === PageTypes.SEARCH ? styles.selectedTab : styles.tab, onClick: () => setSelectedTab(PageTypes.SEARCH) }, "User search"),
            React.createElement("div", { "data-testid": 'addUser', 
                // className={`'tab' ${selectedTab === PageTypes.ADD_USER ? 'selectedTab' : ''}`}
                style: selectedTab === PageTypes.ADD_USER ? styles.selectedTab : styles.tab, onClick: () => setSelectedTab(PageTypes.ADD_USER) }, "Add user"))));
}
